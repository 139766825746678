// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

import { CommonModule, registerLocaleData } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { LoginComponent } from './components/login/login.component';
import { LimitToFilterPipe } from './filters/limitToFilter.pipe';
import { KeysPipe } from './filters/keysValuesFilter.pipe';
import { AuthenticationService } from '@app/services/authentication/authentication.service';
import { UserService } from '@app/services/authentication/user.service';
import { AppRoutingModule } from './app-routing.module';
import { FormValidationService } from './services/common/form.validation.service';
import { FooterComponent } from './components/footer/footer.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BaseHttpRequestOptionsService } from './services/common/base-http-request-options.service';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/shared/loader/loader.component';
import { LoaderInterceptorService } from './services/common/loader/loader.interceptor.service';
import { LoaderService } from './services/common/loader/loader.service';
import { SubscriptionService } from './services/common/subscription.service';
import { LocalFilterModule } from './components/logged-in/local-filter.module';
import localeFr from '@angular/common/locales/fr';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ParametersService } from './services/parameters/parameters.service';
import { ErrorManagerService } from './services/common/error-manager/error-manager.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { AppConfig } from '@app/app.config';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntitiesService } from '@app/services/entities/entities.service';
import { ParticipantsService } from '@app/services/participants/participants.service';
import { IdentityProviderOptions, IdentityProviders, SentryOptions } from '@env/environment.model';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '@app/components/shared/shared.module';
import { firstValueFrom, of } from 'rxjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

registerLocaleData(localeFr);

function initializeKeycloak(config: AppConfig, keycloak: KeycloakService) {
  return () =>
    config.load().then(() => {
      const keycloakConf: IdentityProviderOptions | undefined = config.getConfig(
        'identityProvider'
      ) as IdentityProviderOptions;
      if (keycloakConf?.name !== IdentityProviders.KEYCLOAK) {
        return;
      }

      if (!keycloakConf.keycloakConfig) {
        console.error('Could not retrieve identity provider configuration successfully');
        return firstValueFrom(of(false));
      }

      return keycloak.init({
        config: keycloakConf.keycloakConfig,
        initOptions: {
          onLoad: 'check-sso',
          flow: 'standard',
          pkceMethod: 'S256',
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets', '/clients/public']
      });
    });
}

function initializeSentry(config: AppConfig) {
  return () => {
    config.load().then(() => {
      const sentryConf: SentryOptions | undefined = config.getConfig(
        'sentry'
      ) as SentryOptions;
      Sentry.init({
        environment: sentryConf.environment ?? 'production',
        dsn: sentryConf.dsn,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration()
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', new RegExp(sentryConf.tracePropagationTarget, "i")],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
      });
    })
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderBarComponent,
    LimitToFilterPipe,
    KeysPipe,
    FooterComponent,
    LoaderComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPermissionsModule.forRoot(),
    LocalFilterModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    KeycloakAngularModule,
    MatTableModule,
    MatPaginatorModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    AuthenticationService,
    UserService,
    FormValidationService,
    BaseHttpRequestOptionsService,
    SubscriptionService,
    LoaderService,
    EntitiesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    ParametersService,
    ErrorManagerService,
    ParticipantsService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSentry,
      multi: true,
      deps: [AppConfig]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [AppConfig, KeycloakService, Sentry.TraceService]
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({})
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    }
  ],
  exports: [LocalFilterModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
