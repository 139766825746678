// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export enum CashRole {
  CASHISSUER = 'CashIssuer',
  CASHWALLOWN = 'DedicatedCashWalletOwner',
  CASHWALLUSER = 'DedicatedCashWalletUser',
  CASHWALLMNG = 'DedicatedCashWalletManager',
  CASHWALLCUSTODIAN = 'DedicatedCashWalletCustodian',
  CASHSUPER = 'CashSupervisor',
  DL3SOPERATOR = 'DL3SOperator',
  INTRADAYCREDITPROVIDER = 'IntradayCreditProvider',
  NATIONALCBDCSUPERVISOR = 'NationalCbdcSupervisor'
}

export enum SecurityRole {
  SECISSUER = 'SecuritiesIssuer',
  SECWALLOWN = 'SecuritiesWalletOwner',
  SECWALLMNG = 'SecuritiesWalletManager',
  SECSUPER = 'SecuritiesSupervisor'
}

export type Role = SecurityRole | CashRole;

export const RolesDisplayWalletBased: Record<Role, string> = {
  [SecurityRole.SECISSUER]: 'Securities Issuer',
  [SecurityRole.SECWALLOWN]: 'Securities Wallet Owner',
  [SecurityRole.SECWALLMNG]: 'Securities Wallet Manager',
  [SecurityRole.SECSUPER]: 'Securities Supervisor',
  [CashRole.CASHISSUER]: 'CBDC Issuer',
  [CashRole.CASHWALLOWN]: 'Dedicated Cash Wallet Owner',
  [CashRole.CASHWALLUSER]: 'Dedicated Cash Wallet User',
  [CashRole.CASHWALLMNG]: 'Dedicated Cash Wallet Manager',
  [CashRole.CASHWALLCUSTODIAN]: 'Dedicated Cash Wallet Custodian',
  [CashRole.CASHSUPER]: 'CBDC Supervisor',
  [CashRole.DL3SOPERATOR]: 'DL3S Operator',
  [CashRole.INTRADAYCREDITPROVIDER]: 'Intraday Credit Provider',
  [CashRole.NATIONALCBDCSUPERVISOR]: 'National CBDC Supervisor'
}

export const RolesToDisplayIssuanceFilter: Record<string, boolean> = {
  [CashRole.CASHISSUER]: true,
  [CashRole.DL3SOPERATOR]: true
};
