import { KeycloakOptions } from "keycloak-angular/lib/core/interfaces/keycloak-options";
import { KeycloakConfig } from "keycloak-js";

export interface EnvironmentModel {
  production: boolean;
  debug: boolean | null;
  apiUrl: string;
  octopusUrl: string;
  accessUrl: string;
  theme: string;
  settlementEngineType: string;
  CashNaming: string;
  CashOperationNaming: string;
  appTitle: string;
  logoTitle: string;
  enableCBDCTierbus: boolean | null;
  newConfigSystem?: boolean;
  caEventMode?: string;
  issuerCashWalletAlias: string;
  issuerCashWalletManagerID: string;
  cashOnly: boolean;
  environment: string;
  identityProvider?: IdentityProviderOptions;
  disableBusinessWindows: boolean;
  sentry: SentryOptions;
}

export interface SentryOptions {
  environment: string;
  dsn: string;
  tracePropagationTarget: string;
}

export interface IdentityProviderOptions {
  name: IdentityProviders;
  keycloakConfig?: KeycloakConfig;
}

export enum IdentityProviders {
  KEYCLOAK = "keycloak",
  ACCESS = "access"
}
